import ModalBtn from '@/components/ui/ModalBtn/ModalBtn';
import { sendMetrik } from '@/helpers';
import { BaseOffsetsUI, IMetrika, IMetrikaNewHandler, LinkI, ModalBtnI } from '@/interfaces';
import { sendMetriksConstructor } from '@/metriks/metriksSample';
import { BasicBackgrounds } from '@/style/colors';
import { useRouter } from 'next/router';
import React from 'react';
import { Container, ButtonsLink } from './Buttons.styled';

interface ButtonsProps extends BaseOffsetsUI, IMetrika, IMetrikaNewHandler {
    links?: LinkI[];
    haveBg?: boolean;
    className?: string;
    modals?: ModalBtnI[];
    scrollButtons?: {
        text: string;
        scrollToSelector?: string;
    }[];
    hasSideOffsets?: boolean;
    bgColor?: BasicBackgrounds;
    onOpenModalMetrik?: {
        event: string;
        category: string;
        actions: [
            {
                key: string;
                value: boolean;
            },
            {
                key: string;
            },
            {
                key: string;
            }
        ];
    };
    buttonTextMetrik?: boolean;
}

const Buttons: React.FC<ButtonsProps> = ({
    links,
    haveBg,
    className,
    modals,
    scrollButtons,
    topOffset,
    bottomOffset,
    hasSideOffsets,
    bgColor,
    metriksSample,
    metrikaArray,
    metrika,
    onOpenModalMetrik,
    newMetrikaHandler,
    buttonTextMetrik
}) => {
    const { pathname } = useRouter();

    return (
        <Container
            className={className}
            topOffset={topOffset}
            bottomOffset={bottomOffset}
            hasSideOffsets={hasSideOffsets}
            bgColor={bgColor}
        >
            {links?.map((link, index) => {
                return (
                    <ButtonsLink
                        key={index}
                        {...link}
                        bg={haveBg}
                        newMetrikaHandler={newMetrikaHandler}
                        metrika={
                            (metrika && metrika) ||
                            (metriksSample &&
                                (() =>
                                    sendMetrik(metriksSample.event, metriksSample.category, {
                                        [metriksSample.action]: link.text,
                                        ...(metriksSample.action2 && { [metriksSample.action2]: link.href })
                                    }))) ||
                            (metrikaArray && metrikaArray[index])
                        }
                        clickOnButton={() => {
                            buttonTextMetrik &&
                                sendMetriksConstructor(pathname, 'bannerButtonText', [
                                    { neededKey: 'click-block', newValue: link.metrikValue ? link.metrikValue : link.text },
                                    { neededKey: 'bannerButtonTextId', newValue: true }
                                ]);
                        }}
                    />
                );
            })}

            {modals?.map(({ title, id, altDescription }, i) => (
                <ModalBtn
                    key={i}
                    view={{ viewType: 'rectangle', buttonParams: [true, false] }}
                    id={id}
                    altDescription={altDescription}
                    haveBg={haveBg}
                    onOpenModalFormMetrik={onOpenModalMetrik}
                >
                    {<span onClick={metrika}>{title}</span>}
                </ModalBtn>
            ))}

            {scrollButtons?.map(({ text, scrollToSelector }, i) => (
                <ButtonsLink key={i} scrollToSelector={scrollToSelector} text={text} bg={haveBg} />
            ))}
        </Container>
    );
};

export default Buttons;
